
import {mapGetters} from 'vuex';
import ProviderList from '~/components/external-integrations/ProviderList.vue'
import IconLogoAeqNoir from "~/components/icons/IconLogoAeqNoir.vue";
import ProviderDetailed from "~/components/external-integrations/ProviderDetailed.vue";

export default {
  name: `IntegrationsPage`,
  components: {ProviderDetailed, IconLogoAeqNoir, ProviderList},
  computed: {
    ...mapGetters({
      selectedProvider: `integrations/selectedProvider`
    })
  },
  methods: {
    mounted() {
      this.$store.commit(`integrations/setSelectedProvider`, undefined);
    },
    goToMoreDetails() {

    }
  }
}
